import { GlobalIcon, TooltipHover } from "get-life-storybook-ts";
import BrokerResponse from "../../../../api/request/Broker/Model/Response/BrokerResponse.model";
import { ListProjectsV3 } from "../../../../api/request/Lists/Model/Response/ListResponse.model";
import { DisableRecoverButton, DisableViewButton, DisableViewButtonAdmin, EditButton, enableEmailsSelectra, EnableOtpButton } from "../constants/logicButtons";

export const renderActions = (translate: (key: string) => string, project: ListProjectsV3, recoverLead: (leadId: string, project: any) => void, editLead: (project: any) => void, getOtp: (leadId: string) => void, viewLead: (leadId: string, project: any) => void, broker?: BrokerResponse, DOMAIN?: string) => {
    const isAdmin = broker?.brokerageId === 1;
    const isSelectraEnable = broker?.brokerageId === 666 && !enableEmailsSelectra.includes(broker?.email)
    const DisableRecover = !DisableRecoverButton.includes(project.status) || ((project.hasMaxCapital || project.hasTelesubscription) && !project.hasManualTarification) || isSelectraEnable;
    const DisableRecoverByStatus = !DisableRecoverButton.includes(project.status);
    const DisableRecoverByMaxcapital =  (project.hasMaxCapital || project.hasTelesubscription) && !project.hasManualTarification ;
    const DisableEdit = (project.hasTelesubscription && !project.hasMaxCapital) || isSelectraEnable;
    const DisableEditByTelesub = (project.hasTelesubscription && !project.hasMaxCapital);
    const DisableView = isAdmin ?  DisableViewButtonAdmin.includes(project.status) : DisableViewButton.includes(project.status);
    const DisableOtpButton = process.env.REACT_APP_DOMAIN_LOCALE === "sql_es" && !EnableOtpButton.includes(project.status);

    const AllDisabled = DOMAIN === "sql_es" && (project.market === "ES" || project.market === "AXA_WL_ES");

    const RecoverTooltip = DisableRecoverByMaxcapital ? translate("tables.tootlip.disableRecoverByMaxCapital") : DisableRecoverByStatus ? translate(`tables.tootlip.disableRecoverByStatus${project.status}`) : translate("tables.tootlip.recover");
    const EditTooltip = DisableEditByTelesub ? translate("tables.tootlip.editDisabled") : translate("tables.tootlip.edit");
    const ViewTooltip = DisableView ? translate(`tables.tootlip.disableViewByStatus${project.status}`) : translate("tables.tootlip.view");
    const OTPTooltip = translate(`tables.tootlip.otpButton`);

    if (EditButton.includes(project.status)) {
        return (
            <>
                <TooltipHover content={RecoverTooltip} direction="bottom left">
                    <GlobalIcon iconName="RecoverIcon" onClick={DisableRecover || AllDisabled ? undefined : () => recoverLead(project.leadId, project)} color={DisableRecover || AllDisabled ? "#B3B3B3" : "#423EF4"} className={DisableRecover || AllDisabled ? "" : "pointer"} size="XS" />
                </TooltipHover>
                <TooltipHover content={EditTooltip} direction="bottom left">
                    <GlobalIcon iconName="PencilIcon" onClick={DisableEdit || AllDisabled ? undefined : () => editLead(project)} color={DisableEdit || AllDisabled ? "#B3B3B3" : "#423EF4"} className={DisableEdit || AllDisabled ? "" : "pointer"} size="XS" />
                </TooltipHover>
                <TooltipHover content={OTPTooltip} direction="bottom left">
                <GlobalIcon iconName="LinkToIcon" onClick={DisableOtpButton || AllDisabled ? undefined : () => getOtp(project.leadId)} color={DisableOtpButton || AllDisabled ? "#B3B3B3" : "#423EF4"} className={DisableOtpButton || AllDisabled ? "" : "pointer"} size="XS" />
                </TooltipHover>
            </>
        )
    }
    return (
        <>
            <TooltipHover content={RecoverTooltip} direction="bottom left">
                <GlobalIcon iconName="RecoverIcon" onClick={DisableRecover || AllDisabled ? undefined : () => recoverLead(project.leadId, project)} color={DisableRecover || AllDisabled ? "#B3B3B3" : "#423EF4"} className={DisableRecover || AllDisabled ? "" : "pointer"} size="XS" />
            </TooltipHover>
            <TooltipHover content={ViewTooltip} direction="bottom left">
                <GlobalIcon iconName="EyeIcon" onClick={DisableView || AllDisabled ? undefined : () => viewLead(project.leadId, project)} color={DisableView || AllDisabled ? "#B3B3B3" : "#423EF4"} className={DisableView || AllDisabled ? "" : "pointer"} size="XS" />
            </TooltipHover>
            <TooltipHover content={OTPTooltip} direction="bottom left">
                    <GlobalIcon iconName="LinkToIcon" onClick={DisableOtpButton || AllDisabled ? undefined : () => getOtp(project.leadId)} color={DisableOtpButton || AllDisabled ? "#B3B3B3" : "#423EF4"} className={DisableOtpButton || AllDisabled ? "" : "pointer"} size="XS" />
            </TooltipHover>
        </>
    )
}