import Earnings from "../views/earnings/Earnings.js";
import Help from "../views/help/Help.js";
import Incidents from "../views/incidents/Incidents.js";
import Login from "../views/login/Login.js";
import LoginOtp from "../views/loginOTP/login-otp.container.jsx";
import Payment from "../views/payment/Payment.js";
import Projects from "../views/projects/Projects.jsx";
import RecoverPassword from "../views/login/recoverPassword/RecoverPassword.js";
import Register from "../views/register/Register.js";
import RegisterEmployee from "../views/registerEmployee/RegisterEmployee.js";
import ResetDone from "../views/login/recoverPassword/ResetDone.js";
import TicketList from "../views/incidents/TicketList";
import WaitingSignature from "../views/WaitingSignature/WaitingSignature.js";
import Welcome from "../views/welcome/Welcome.js";
import Questions from "../views/dashboard/components/Questions.js";
import CardValidation from "../views/CardValidation/CardValidation.js";
import { ROLES } from "../utils/roles.js";
import NotFound from "../views/components/NotFound/NotFound.component.jsx";
import { Navigate } from "react-router-dom";
import Employees from "../views/employees/Employees.jsx";
import { getAllowedRoutes } from "../utils/routesUtils.js";
import CompanyInformation from "../views/Rebranding/pages/CompanyInformation/CompanyInformation.view.tsx";
import PostStack from "../views/Rebranding/pages/PostStack/PostStack.component";
import CheckPoint from "../views/Rebranding/pages/Checkpoint/CheckPoint.component";
import VelogicaResume from "../views/components/VelogicaResume/VelogicaResume.tsx";
import ConfirmationPage from "../views/Confirmation/Confirmation.tsx";
import Landing from "../views/Rebranding/pages/Landing/Landing.component.tsx";
import DashboardMiddleware from "../views/dashboard/DashboardMiddleware.tsx";
import CheckPointSQL from "../views/Rebranding/pages/CheckpointSQL/CheckPointSQL.component";
import LandingSQL from "../views/Rebranding/pages/LandingSQL/LandingSQL.tsx";

const DOMAIN = process.env.REACT_APP_DOMAIN_LOCALE;

const publicRoutes = [
  {
    path: "/landing/:slug",
    component: DOMAIN === "sql_es" ? <LandingSQL /> : <Landing />,
  },
  {
    path: "/resumeVelogica",
    component: <VelogicaResume />,
  },
  {
    path: "*",
    component: <NotFound />,
  },
];

const redirects = [
  {
    path: "/",
    component: <Navigate to="/login" />,
  },
  {
    path: "/dashboard",
    component: <Navigate to="/dashboard/calculator" />,
  },
];

const protectedRoutes = [
  {
    path: "/resetdone",
    component: <ResetDone />,
  },
  {
    path: "/card-validation",
    component: <CardValidation />,
  },
  {
    path: "/login",
    component: <LoginOtp />,
  },
  {
    path: "/login/:OTP",
    component: <LoginOtp />,
  },
  {
    path: "/login-master",
    component: <Login />,
  },
  {
    path: "/register",
    component: <Register />,
  },
  {
    path: "/register/employee/:id",
    component: <RegisterEmployee />,
  },
  {
    path: "/resetpassword",
    component: <RecoverPassword />,
  },
];

const privateRoutes = [
  {
    path: "/checkpoint",
    component: (
      <>
        {
          {
            fr: <CheckPoint />,
            es: <CheckPoint />,
            it: <CheckPoint />,
            wl_es: <CheckPoint />,
            sql_es: <CheckPointSQL />,
          }[DOMAIN]
        }
      </>
    ),
    permission: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.EMPLOYEE],
  },
  {
    title: "navbar.link.dashboard",
    path: "/dashboard/calculator",
    component: <DashboardMiddleware />,
    permission: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.EMPLOYEE],
  },
  {
    path: "/questions",
    component: <Questions />,
    permission: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.EMPLOYEE],
  },
  {
    path: "/welcome",
    component: <Welcome />,
    permission: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.EMPLOYEE],
  },
  {
    title: "",
    path: "/help",
    component: <Help />,
    permission: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.EMPLOYEE],
  },
  {
    title: "navbar.link.projects",
    path: "/projects",
    component: <Projects />,
    permission: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.EMPLOYEE],
  },
  {
    path: "/poststack",
    component: <PostStack />,
    permission: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.EMPLOYEE],
  },
  {
    title: "navbar.link.incidents",
    path: "/incidents",
    component: <TicketList />,
    permission: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.EMPLOYEE],
  },
  (DOMAIN === "es" || DOMAIN === "pt" || DOMAIN === "it" || DOMAIN === "sql_es") && {
    title: "navbar.link.earnings",
    path: "/earnings",
    component: <Earnings />,
    permission: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
  },
  {
    title: "navbar.link.employees",
    path: "/employees",
    component: <Employees />,
    permission: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
  },
  {
    path: "/incidents/newticket",
    component: <Incidents />,
    permission: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.EMPLOYEE],
  },

  {
    title: "",
    path: "/pay",
    component: (
      <>
        {
          {
            fr: <Payment />,
            es: <Payment />,
            it: <Payment />,
            wl_es: <Payment />,
            sql_es: <Payment />,
          }[DOMAIN]
        }
      </>
    ),
    permission: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.EMPLOYEE],
  },
  {
    path: "/waitingsignature",
    component: <WaitingSignature />,
    permission: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.EMPLOYEE],
  },
  {
    path: "/confirmation",
    component: <ConfirmationPage />,
    permission: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.EMPLOYEE],
  },
  {
    path: "/company_info",
    component: <CompanyInformation />,
    permission: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
  },
];

const allowedRoutes = (rol) =>
  rol ? getAllowedRoutes({ routes: privateRoutes, roles: rol }) : privateRoutes;

export const mapRoutes = {
  allowedRoutes,
  privateRoutes,
  protectedRoutes,
  publicRoutes,
  redirects,
};
