import React from "react";
import { intentionCheckOut } from "../../../utils/staticData";
import numberFormat from "../../../utils/numberFormat";
import CheckIcon from "@mui/icons-material/Check";
import "../css/_productCard.scss";
import CrossedOut from "../../Rebranding/atoms/CrossedOut/CrossedOut.component";
import InfoBox from "../../Rebranding/molecules/InfoBox/InfoBox.component";
import TicketIcon from "../../Rebranding/atoms/Icons/TicketIcon";
import { ButtonDS } from "get-life-storybook-ts";

const SelectedPrice = ({ billingSelect, election, productCard, translate }) => (
  <p className="productCard--main__price">
    <span
      className={` ${billingSelect === "monthly" &&
        (election ? "periodSelect" : "periodSelectElection")
        }`}
    >
      {numberFormat(
        productCard.isPromoted
          ? productCard.monthlyPromotedPrice
          : productCard.monthlyPrice,
        "price"
      )}
      {translate("card.period.monthly")}
    </span>{" "}
    {translate("card.period.or")}{" "}
    <span
      className={` ${billingSelect === "yearly" &&
        (election ? "periodSelect" : "periodSelectElection")
        }`}
    >
      {numberFormat(
        productCard.isPromoted
          ? productCard.annualPromotedPrice
          : productCard.annualPrice,
        "price"
      )}
      {translate("card.period.yearly")}
    </span>
  </p>
);

const ProductCard = (props) => {
  const {
    billingSelect,
    election,
    productCard,
    setShowModalCoupon,
    translate,
    setDownloadableOpen,
  } = props;

  return (
    <>
      <article className="productCard">
        <section className="productCard--header">
          <div>
            <h3 className="productCard--header__title">
              {translate("checkpoint.cardheader.title")}
            </h3>
            <p className="productCard--header__subtitle">
              Life5 {intentionCheckOut[productCard.intention]}
            </p>
          </div>
        </section>
        <section className="productCard--main">
          {productCard.isPromoted && (
            <>
              <CrossedOut
                className="productCard--main__delete"
                data={
                  <p>
                    {numberFormat(productCard.monthlyPrice, "price")}{" "}
                    {translate("card.period.monthly")}{" "}
                    {translate("card.period.or")}{" "}
                    {numberFormat(productCard.annualPrice, "price")}{" "}
                    {translate("card.period.yearly")}
                  </p>
                }
              />
            </>
          )}
          <SelectedPrice
            billingSelect={billingSelect}
            election={election}
            productCard={productCard}
            translate={translate}
          />
          <div className="productCard--main__div">
            <CheckIcon />
            <p className="productCard--main__details">
              {translate("payment.card.death")}{" "}
              {numberFormat(productCard.capital, "capital")}
            </p>
          </div>
          {productCard.insuranceType === "premium" ? (
            <div className="productCard--main__div">
              <CheckIcon />
              <p className="productCard--main__details">
                {translate("payment.card.ipa")}
                {numberFormat(productCard.capital, "capital")}
              </p>
            </div>
          ) : (
            ""
          )}
        </section>
        <section className="productCard--footer w-full">
          <div className="productCard--footer__button w-full">
            <ButtonDS
              leftIcon="FileDownload"
              label={translate("checkpoint.documents.download")}
              onClick={() => setDownloadableOpen(true)}
              size="50"
              className="flex-1 w-full"
              buttonType="secondary"
            />
          </div>
        </section>
        {(productCard.isPromoted && process.env.REACT_APP_DOMAIN_LOCALE === "es") && (
          <InfoBox
            className="productCard__infoBox"
            icon={<TicketIcon />}
            infoIcon={false}
            onClick={() => setShowModalCoupon(true)}
            text={`${productCard.beforePromoText} ${productCard.monthlyPromotedPrice} €`}
          />
        )}
      </article>
    </>
  );
};

export default ProductCard;
