import { Chip } from "get-life-storybook-ts";
import { Stages } from "../constants/stages";
import { ListProjectsV3 } from "../../../../api/request/Lists/Model/Response/ListResponse.model";

export const renderstatus = (
  translate: (key: string) => string,
  project: ListProjectsV3
) => {
  return Stages(translate).map((stage) => {
    const chipsArray = [];
    if (stage.value.includes(project.status)) {
      if (project.coverage === "eg") {
        chipsArray.push(
          <Chip
            type={"secondary"}
            icon={"WiderUmbrellaIcon"}
            tooltip={translate("projects.tooltip.eg")}
            className="p-[4px] border border-[#5D5AE9]"
          />
        );
      }

      if (project.hasMaxCapital) {
        chipsArray.push(
          <Chip
            type={"primary"}
            icon={"CashIcon"}
            tooltip={translate("projects.tooltip.maxCapital")}
            className="p-[4px]"
          />
        );
      }

      if (project.hasTelesubscription) {
        chipsArray.push(
          <Chip
            type={"primary"}
            icon={"MedicalIcon"}
            tooltip={translate("projects.tooltip.telesubscription")}
            className="p-[4px]"
          />
        );
      }

      chipsArray.push(
        <Chip
          text={stage.title}
          type={stage.chipType}
          icon={stage.chipIcon}
          className="py-[4px] whitespace-nowrap"
        />
      );
      return chipsArray;
    }
  });
};
