import { useContext, useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useNavigate } from "react-router";
import CustomRadio from "../../components/CustomRadio/CustomRadio";
import { formatPriceTwoDecimals, paymentRadius } from "../../../utils/staticData";
import ModalSendOtp from "./ModalSendOtp/ModalSendOtp";
import apiSendOtpPost from "../../../api/request/apiSendOtpPost";
import HttpTrackingRepository from "../../../api/request/Tracking/tracking.service";
import { GoogleContext } from "../../../contexts/GoogleTagManager.context";
import { GUARANTEE } from "../../../utils/staticData";
import HttpLeadRepository from "../../../api/request/Lead/lead.service";
import { getTrackingUtms } from "../../../api/request/apiLeadTrackingGet";
import apiCreateProfile from "../../../api/request/apiCreateProfile";
import apiFirstSyncProfile from "../../../api/request/apiFirstSyncProfile";
import apiConfigOfCustomer from "../../../api/request/apiConfigOfCustomer";
import { ButtonDS, GlobalIcon } from "get-life-storybook-ts";

const CardPayment = (props) => {
  const {
    leadId,
    token,
    paymentMethod,
    handlePaymentMethod,
    brokerId,
    productCard,
    translate,
    broker,
    undoSelectedPaymentMethod
  } = props;

  const [infoStripe, setInfoStripe] = useState();
  const [textButton, setTextButton] = useState(translate("payment.button.sendLink"));
  const textButtonCard = translate("payment.button.pay");
  const [showModal, setShowModal] = useState(false);
  const [receiver, setReceiver] = useState("");
  const [loading, setLoading] = useState(false);
  const [cardElementStatus, setCardElementStatus] = useState({complete: false});
  const [cardIncomplete, setCardIncomplete] = useState(null);
  const [cardError, setCardError] = useState(null);
  const stripe = useStripe();
  const elements = useElements();

  const { handleTrackerQuestion } = useContext(GoogleContext);

  const navigate = useNavigate();

  const leadRepository = new HttpLeadRepository(token);
  const trackingRepository = new HttpTrackingRepository(token);

  const handleSubmit = async () => {
    await apiCreateProfile(token, leadId).then(async () => {
      await apiConfigOfCustomer(token, leadId).then(async (response) => {
        setInfoStripe(response);
        await confirmStripe(response);
        await apiFirstSyncProfile(token, leadId).then(async (response) => {
          if(response === "error") {
            setCardError(true);
            return;
          }
          navigate("/confirmation");
        })
      });
    });
  };

  const confirmStripe = async (setupIntent) => {
    let eventDetails = [
      {
        leadId: leadId,
        paymentMethod: "card",
        token: token,
        brokerEmployeeId: brokerId,
      },
    ];

    trackingRepository.add({ eventType: "PAYMENT_TRY", eventDetails });

    if (!stripe || !elements) {
      eventDetails = [
        {
          leadId: leadId,
          paymentMethod: "card",
          errorMessage: "INTENT_NOT_LOADED",
          token: token,
          brokerEmployeeId: brokerId,
        },
      ];
      trackingRepository.add({ eventType: "PAYMENT_ERROR", eventDetails });
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setLoading(true);

    const result = await stripe.confirmCardSetup(setupIntent.clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: setupIntent.name + " " + setupIntent.lastName,
        },
      },
    });
    if (result.error) {
      eventDetails = [
        {
          leadId: leadId,
          paymentMethod: "card",
          errorMessage: result.error,
          token: token,
          brokerEmployeeId: brokerId,
        },
      ];
      trackingRepository.add({ eventType: "PAYMENT_ERROR", eventDetails });
      setLoading(false);
    } else {
      eventDetails = [
        {
          leadId: leadId,
          token: token,
          brokerEmployeeId: brokerId,
        },
      ];
      trackingRepository.add({
        eventType: "PAYMENT_SUCCESS",
        eventDetails,
      });

      await leadRepository.getPostStackData(leadId).then((async (leadResponse) => {
        const utms = await getTrackingUtms(leadId);
        const eventData = {
          page: window.location.href,
          leadId: leadId,
          calculatedCapital: parseInt(productCard.capital),
          coverage: GUARANTEE[productCard.insuranceType],
          calculatedPremium: parseFloat(productCard.monthlyPrice),
          intent: productCard.intention,
          paymentMethod: "card",
          brokerEmployeeId: brokerId,
          brokerId: broker.brokerageId,
          platform: "Broker",
          discountedPremium: productCard.promotion ? parseFloat(formatPriceTwoDecimals(productCard.promotion.price)) : parseFloat(formatPriceTwoDecimals(productCard.monthlyPrice)),
          paymentPeriod: productCard.period ? productCard.period : "monthly",
          policyId: leadId,
          ...utms
        }

        handleTrackerQuestion({
          type: "FS",
          event: "paymentMethodSet",
          data: {
            birthDate: leadResponse.lead.birthdate,
            ...eventData
          }
        });
        handleTrackerQuestion({
          type: "GA",
          data: {
            event: "paymentMethodSet",
            birthDate: leadResponse.lead.birthdate,
            ...eventData
          },
        });

        handleTrackerQuestion({
          type: "FS",
          event: "policyPurchased",
          data: {
            birthDate: new Date(leadResponse.lead.birthdate),
            ...eventData
          }
        });
        handleTrackerQuestion({
          type: "GA",
          data: {
            event: "policyPurchased",
            birthDate: new Date(leadResponse.lead.birthdate),
            ...eventData
          },
        });
      }))
    }
  };

  const sendLinkOtp = async () => {
    setShowModal(true);
    if (paymentMethod === "sms") {
      setReceiver(infoStripe.phone);
    } else {
      setReceiver(infoStripe.email);
    }
  };

  const sendEmail = async () => {
    let channel = paymentMethod;
    await apiSendOtpPost(token, leadId, channel, receiver).then(async (response) => {
      if (response === "ok") {
        setTextButton(translate("payment.button.linkSent"));
        setShowModal(false);

        await leadRepository.getPostStackData(leadId).then(async (leadResponse) => {
          const event = "paymentMethodOTPrequest";
          const utms = await getTrackingUtms(leadId);
          const eventData = {
            leadId: leadId,
            calculatedCapital: productCard.capital,
            coverage: GUARANTEE[productCard.insuranceType],
            calculatedPremium: productCard.monthlyPrice,
            intent: productCard.intention,
            page: window.location.href,
            brokerEmployeeId: brokerId,
            brokerId: broker.brokerageId,
            email: leadResponse.lead.email,
            phone: leadResponse.lead.phone ?? "",
            postalCode: leadResponse.lead.zipcode,
            gender: leadResponse.lead.gender,
            platform: "Broker",
            utm_source: utms.utm_source ?? "brokerapp",
            utm_medium: utms.utm_medium ?? broker.brokerageId,
            utm_campaign: utms.utm_campaign ?? brokerId,
            utm_content: utms.utm_content,
            utm_term: utms.utm_term,
            utm_test: utms.utm_test,
            fbclid: utms.fbclid,
            gclid: utms.gclid,
            entryPage: utms.entryPage,
            referrerUrl: utms.referrerUrl
          }

          handleTrackerQuestion({
            type: "FS",
            event: event,
            data: {
              birthDate: leadResponse.lead.birthdate,
              ...eventData
            }
          });
          handleTrackerQuestion({
            type: "GA",
            data: {
              event,
              birthDate: leadResponse.lead.birthdate,
              ...eventData
            },
          });

        });
      }
    });
  };

  const onCardElementChange = (e) => setCardElementStatus(e);

  const onCardElementBlur = () => {
    if(cardElementStatus.error) return setCardError(true);
    if(cardElementStatus.complete === false) return setCardIncomplete(true);
    setCardIncomplete(false);
    setCardError(false);
  }

  return (
    <div className="card-select-payment-type flex flex-col gap-4">
      <div className="subcontainer flex flex-col pb-0">
        <span className="BodyM font-bold text-[#3430E3]">{translate("payment.paymentMethod.cardTitle")}</span>
        {paymentMethod !== "now" ? (
          <span className="BodyM font-regular text[#555555]">{translate("payment.paymentMethod.cardSubTitle")}</span>
        ) : (
          <span className="BodyM font-regular text[#555555]">{translate("payment.paymentMethod.cardSubTitleNow")}</span>
        )}

        <CustomRadio
          answer={paymentMethod}
          toMap={paymentRadius(translate)}
          handleValue={handlePaymentMethod}
          vertical={true}
          disabled={
            textButtonCard === translate("payment.button.payDone") ||
            textButton === translate("payment.button.linkSent")
              ? true
              : false
          }
        />
        {paymentMethod === "now" && (
          <div className="container-card">
            <CardElement
              onChange={(e) => {
                onCardElementChange(e);
              }}
              onBlur={onCardElementBlur}
            />
            {cardError === true || cardIncomplete === true ? (
              <p className="!text-[#E84B4B] flex flex-row gap-[8px] items-center">
                <GlobalIcon
                  size="XXS"
                  iconName="AlertCircleIcon"
                  color="currentColor"
                />
                <span className="BodyS">
                  {cardError
                    ? translate("payment.paymentMethod.errorCard")
                    : cardIncomplete
                    ? translate("payment.paymentMethod.incompletedCard")
                    : ""}
                </span>
              </p>
            ) : null}
            <p>{translate("payment.paymentMethod.now3DS")}</p>
          </div>
        )}
      </div>
      <div className="button-container-payment flex flex-col gap-4">
        {paymentMethod === "now" && (
          <ButtonDS
            label={textButtonCard}
            onClick={handleSubmit}
            disabled={
              cardError !== false || cardIncomplete !== false || loading === true
                ? true
                : false
            }
            loading={loading}
          />
        )}
        {(paymentMethod === "email" || paymentMethod === "sms") && (
          <div className="flex flex-col gap-[8px]">
            <ButtonDS
              label={textButton}
              disabled={
                textButton === translate("payment.button.linkSent")
                  ? true
                  : false
              }
              onClick={sendLinkOtp}
            />
            {textButton === translate("payment.button.linkSent") && (
              <ButtonDS
                className="!w-fit mx-auto"
                onClick={sendLinkOtp}
                buttonType="tertiary"
                ghost={true}
                size={"32"}
                label={`${translate("payment.link.question")} ${translate(
                  "payment.button.reSend"
                )}`}
              />
            )}
          </div>
        )}
        <ButtonDS
          label={translate("payment.paymentMethod.changePaymentMethod")}
          buttonType="secondary"
          onClick={undoSelectedPaymentMethod}
        />
      </div>
      <ModalSendOtp
        showModal={showModal}
        setShowModal={setShowModal}
        sendEmail={() => sendEmail()}
        paymentMethod={paymentMethod}
        setReceiver={setReceiver}
        receiver={receiver}
        translate={translate}
      />
    </div>
  );
};

export default CardPayment;
