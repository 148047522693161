/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import { loadStripe } from "@stripe/stripe-js";
import CardPayment from "./components/CardPayment";
import PaymentMethod from "./components/PaymentMethod";
import SepaPayment from "./components/SepaPayment";
import apiLeadPeriodUpdate from "../../api/request/apiLeadPeriodUpdate";
import "./css/_paymentMain.scss";
import ProductCard from "./components/ProductCard";
import { Elements } from "@stripe/react-stripe-js";
import { billingType } from "../../utils/staticData";
import CustomRadio from "../components/CustomRadio/CustomRadio";
import HttpLeadRepository from "../../api/request/Lead/lead.service";
import { ButtonDS, GlobalIcon, Input, RadioButton } from "get-life-storybook-ts";
import RenderInput from "get-life-storybook-ts/lib/molecules/RenderInput/RenderInput";
import numberFormat from "../../utils/numberFormat";
import DownloadableFilesModalDS from "../components/DownloadableFiles/DownloadableFilesModalDS";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY, {
  locale: process.env.REACT_APP_DOMAIN_LOCALE,
  stripeAccount: process.env.REACT_APP_STRIPE_ACCOUNT,
});

const PaymentMain = (props) => {
  const {
    brokerId,
    election,
    handlePaymentCard,
    handlePaymentSepa,
    undoSelectedPaymentMethod,
    leadId,
    paymentCard,
    paymentSepa,
    productCard,
    setShowModalCoupon,
    token,
    translate,
    broker,
    utm,
    sqlInfo
  } = props;

  const DOMAIN = process.env.REACT_APP_DOMAIN_LOCALE;

  const [billingSelect, setBillingSelect] = useState("monthly");
  const [clientSecret, setClientSecret] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [setupIntent, setSetupIntent] = useState([]);
  const [downloadableFiles, setDownloadableFiles] = useState();
  const [downloadableOpen, setDownloadableOpen] = useState(false);

  const leadRepository = new HttpLeadRepository(token);

  const handleSetupIntent = (data) => {
    setSetupIntent(data);
  };

  const handlePaymentMethod = (e) => {
    setPaymentMethod(e);
  };

  useEffect(() => {
    apiLeadPeriodUpdate(token, leadId, billingSelect).then((response) => {
      if (response === "OK") {
        setBillingSelect(billingSelect);
      }
    });
  }, []);

  React.useEffect(() => {
    const getDownloadableFiles = async () => {
      const _downloadableFiles = await leadRepository.getAvailableDocuments(leadId);
      setDownloadableFiles(_downloadableFiles);
    };
    getDownloadableFiles();
  }, [leadId]);

  const options = {
    // passing the client secret obtained from the server
    clientSecret: `${clientSecret}`,
  };

  const updatePeriod = async (period) => {
    await apiLeadPeriodUpdate(token, leadId, period).then((response) => {
      if (response === "OK") {
        setBillingSelect(period);
      }
    });
  };

  const RenderGuarantee = ({ guarantee }) => {
    return (
      <div className="flex gap-4 justify-between">
        <div className="flex gap-2">
          <GlobalIcon
            iconName="TickIcon"
            size="XS"
          />
          <span className="BodyM font-bold text-[#424242]">{translate(`checkpoint.multiproduct.${guarantee.trait.toLowerCase()}`)}</span>
        </div>
        <span className="BodyL font-light text-[#555]">{numberFormat(guarantee.capital, "capital")}</span>
      </div>
    )
  }

  const RenderTotalCapitals = ({ capitals }) => {
    let total = 0;

    const capital = capitals && Object.keys(capitals).map((key) => capitals[key]?.capital);
    capital && capital.forEach(function (uniqueCapital) {
      total += uniqueCapital;
    });

    return (
      <>
        <span className="BodyM font-bold text-[#424242]">{translate("payment.resume.total")}</span>
        <span className="BodyL font-bold text-[#555]">{numberFormat(total, "capital")}</span>
      </>
    )
  }

  const ProductSql = () => {
    return (
      <>
        <div className="w-[528px] flex flex-col pb-[18px] flex-col justify-center items-start gap-6 self-stetch border-[1px] border-[#9A98F1] rounded-xl shadow shadow-[#4242421a] bg-white">
          <div className="flex w-full h-[150px] p-6 flex-col justify-center items-center gap-[18px] self-center border-b-[1px] border-[#A09FFA] rounded-t-xl bg-[#ECF0FF]">
            <div className="inline-flex flex-col items-center justify-center gap-[18px] px-4 py-0 relative self-stretch flex-[0_0_auto] mr-[-1.00px] bg-[#ECF0FF] rounded-lg">
              <div className="inline-flex items-start gap-9 px-0 py-[19px] relative flex-[0_0_auto]">
                <div className="inline-flex flex-col items-center gap-2 relative flex-[0_0_auto]">
                  <div className="relative w-fit mt-[-1.00px] BodyS font-light text-[#7B7B7B]">
                    <p
                      className="BodyM"
                      dangerouslySetInnerHTML={{ __html: sqlInfo?.promotion?.beforePromoText || "" }}
                    />
                  </div>
                  <div className="inline-flex items-start justify-end relative flex-[0_0_auto]">
                    <div className={`H2 ${billingSelect === "monthly" ? "text-purple" : "text-[#7B7B7B]"}`}>
                      {numberFormat(sqlInfo?.prices?.monthly.promoted, "price")}
                    </div>
                    <div className={`BodyS ${billingSelect === "monthly" ? "text-purple" : "text-[#7B7B7B]"}`}>
                      {translate("card.period.monthly")}
                    </div>
                  </div>
                  <div className="relative w-fit BodyS font-light text-[#7B7B7B]">
                    <p
                      className="BodyM"
                      dangerouslySetInnerHTML={{ __html: sqlInfo?.promotion?.afterPromoText || "" }}
                    />
                  </div>
                </div>
                <div className="inline-flex flex-col items-center justify-center gap-2 relative self-stretch flex-[0_0_auto]">
                  <div className="inline-flex flex-col items-start justify-center gap-1 relative flex-[0_0_auto]">
                    {sqlInfo?.promotion?.promotionalCode ? (
                      <>
                        <div className="BodyS font-light text-[#7B7B7B]">
                          {translate("checkpoint.promotion.applied")}
                        </div>
                        <div className="inline-flex items-start justify-end relative flex-[0_0_auto]">
                          <div className={`H2 ${billingSelect === "yearly" ? "text-purple" : "text-[#7B7B7B]"}`}>
                            {numberFormat(sqlInfo?.prices?.yearly.promoted, "price")}
                          </div>
                          <div className={`BodyS ${billingSelect === "yearly" ? "text-purple" : "text-[#7B7B7B]"}`}>
                            {translate("card.period.yearly")}
                          </div>
                        </div>
                        <div className="relative w-fit BodyS font-light text-[#7B7B7B] line-through">
                          {numberFormat(sqlInfo?.prices?.yearly.real, "price")} €{translate("card.period.yearly")}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="inline-flex items-start justify-end relative flex-[0_0_auto]">
                          <div className={`H2 ${billingSelect === "yearly" ? "text-purple" : "text-[#7B7B7B]"}`}>
                            {numberFormat(sqlInfo?.prices?.yearly.real, "price")}
                          </div>
                          <div className={`BodyS ${billingSelect === "yearly" ? "text-purple" : "text-[#7B7B7B]"}`}>
                            {translate("card.period.yearly")}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" flex gap-6 flex-col px-8 py-4 w-full">
            <div className="flex gap-4">
              <GlobalIcon
                iconName="FileTextIcon"
                size="XS"
                color="var(--primary-color)"
              />
              <span className="BodyL font-bold text-purple">{translate("payment.resume.guarantee")}</span>
            </div>
            <div className="flex flex-col gap-4 px-10 pb-4 border-b-[1px] border-[#686868]">
              {
                sqlInfo?.capitals && Object.keys(sqlInfo.capitals).map((key) => {

                  return (
                    <RenderGuarantee
                      guarantee={sqlInfo.capitals[key]}
                    />
                  )
                })
              }
            </div>
            <div className="flex justify-between	">
              <RenderTotalCapitals
                capitals={sqlInfo.capitals}
              />
            </div>

          </div>
        </div>
        <div className="flex pt-8 flex-row-reverse">
          <ButtonDS
            leftIcon="FileDownload"
            label={translate("checkpoint.documents.download")}
            onClick={() => setDownloadableOpen(true)}
            size="50"
            className=""
            buttonType="tertiary"
          />
        </div>
      </>
    )
  }

  return (
    <div className="flex flex-col pt-10 px-20 gap-14 bg-white h-screen">
      <span className="H3 font-bold text-[#424242]">{translate("payment.title")}</span>
      <section className="payment--section">
        <div className="payment--section__left">
          {DOMAIN !== "sql_es" ? (
            <ProductCard
              billingSelect={billingSelect}
              election={election}
              productCard={productCard}
              setShowModalCoupon={setShowModalCoupon}
              translate={translate}
              downloadableOpen={downloadableOpen}
              setDownloadableOpen={setDownloadableOpen}
              downloadableFiles={downloadableFiles}
            />
          ) : <ProductSql />}

        </div>
        {election && (
          <article className="payment--section__right flex flex-col gap-6 px-6 py-8">
            {(DOMAIN === "es" || DOMAIN === "fr" || DOMAIN === "sql_es") && (
              <div className="flex flex-col bg-[#F5F7FA] py-8 px-6 rounded-xl gap-6 w-[610px]">
                <div className="flex gap-4">
                  <GlobalIcon
                    iconName="ReportMoneyIcon"
                    size="XS"
                    color="var(--primary-color)"
                  />
                  <span className="BodyL font-bold text-[#424242]">
                    {translate("payment.billingtype.title")}
                  </span>
                </div>
                <div className="flex flex-row gap-6">
                  {/* <Input 
                    checked={billingSelect === "monthly"}
                    value={"monthly"}
                    onChange={() => updatePeriod("monthly")}
                    label={translate("payment.paymentMethod.monthly")}
                    inputMode="radio"
                    containerStyle="flex flex-row w-full"
                  />

                  <Input 
                    onChange={() => updatePeriod("yearly")}
                    value={"yearly"}
                    checked={billingSelect === "yearly"}
                    label={translate("payment.paymentMethod.yearly")}
                    inputMode="radio"
                    containerStyle="flex flex-row w-full"
                  /> */}
                  <RadioButton
                    checked={billingSelect === "monthly"}
                    value={"monthly"}
                    onChange={() => updatePeriod("monthly")}
                    label={translate("payment.paymentMethod.monthly")}
                  />
                  <RadioButton
                    onChange={() => updatePeriod("yearly")}
                    value={"yearly"}
                    checked={billingSelect === "yearly"}
                    label={translate("payment.paymentMethod.yearly")}
                  />
                </div>
              </div>
            )}
            <PaymentMethod
              handlePaymentCard={handlePaymentCard}
              handlePaymentSepa={handlePaymentSepa}
              billingSelect={billingSelect}
              translate={translate}
            />
          </article>
        )}
        {(paymentCard || paymentSepa) && (
          <div className="payment--section__right flex flex-col bg-[#F5F7FA] py-8 px-6 rounded-xl gap-6">
            <Elements stripe={stripePromise}>
              <div className="flex gap-4">
                <GlobalIcon
                  iconName="ReportMoneyIcon"
                  size="XS"
                  color="var(--primary-color)"
                />
                <span className="BodyL font-bold text-[#424242]">
                  {translate("payment.paymentMethod.title")}
                </span>
              </div>
              {paymentCard && (
                <CardPayment
                  options={options}
                  handleSetupIntent={handleSetupIntent}
                  setupIntent={setupIntent}
                  leadId={leadId}
                  token={token}
                  clientSecret={clientSecret}
                  handlePaymentMethod={handlePaymentMethod}
                  paymentMethod={paymentMethod}
                  brokerId={brokerId}
                  productCard={productCard}
                  translate={translate}
                  broker={broker}
                  utm={utm}
                  undoSelectedPaymentMethod={undoSelectedPaymentMethod}
                />
              )}
              {paymentSepa && (
                <SepaPayment
                  options={options}
                  handleSetupIntent={handleSetupIntent}
                  setupIntent={setupIntent}
                  leadId={leadId}
                  token={token}
                  clientSecret={clientSecret}
                  handlePaymentMethod={handlePaymentMethod}
                  paymentMethod={paymentMethod}
                  brokerId={brokerId}
                  productCard={productCard}
                  broker={broker}
                  translate={translate}
                  undoSelectedPaymentMethod={undoSelectedPaymentMethod}
                />
              )}
            </Elements>
          </div>
        )}
        <DownloadableFilesModalDS
          open={downloadableOpen}
          setOpen={setDownloadableOpen}
          downloadablePdfs={downloadableFiles}
        />
      </section>
    </div>
  );
};

export default PaymentMain;
