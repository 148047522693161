/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import apiLeadQuestionPost from "../../../api/request/apiLeadQuestionPost";
import SelectGetlife from "../select/SelectGetlife";
import "./question.scss";
import CalendarExtendedGetlife from "../calendarGetlife/CalendarExtendedGetlife";
import KEYS from "../../../utils/keyboardKeys";
import { ButtonDS, QuestionDateInput, TextInput } from "get-life-storybook-ts";
import { I18nContext } from "../../../contexts/i18n.context";
import { GoogleContext } from "../../../contexts/GoogleTagManager.context";
import VelogicaQuestion from "../VelogicaQuestion/VelogicaQuestion";
import HttpLeadRepository from "../../../api/request/Lead/lead.service";
import { GUARANTEE } from "../../../utils/staticData";
import { getTrackingUtms } from "../../../api/request/apiLeadTrackingGet";

export default function Question(props) {
  const {
    answered,
    answers,
    brokerId,
    leadId,
    token,
    content,
    handleNextQuestion,
    handleIdEdited,
    isEditing,
    label,
    questionId,
    type,
    componentName,
    previousIllnessesAnswered,
    broker,
    question,
    isNotEditable
  } = props;

  const {
    state: { translate },
  } = useContext(I18nContext);

  const { handleTrackerQuestion } = useContext(GoogleContext);

  /**
   * Enabled edit button for brokerId 1 or BrokerFR except for the Velogica question.
  */
  const BROKER_ES = broker.brokerageId === 1;
  const BROKER_FR = broker.brokerageId === 459;
  const BROKER_IT = broker.brokerageId === 548;

  const isGetlifeBroker = BROKER_ES || (BROKER_FR && componentName !== "external_underwriting") || BROKER_IT;
  const [answer, setAnswer] = useState(props.answer);
  const [defaultValue, setDefaultValue] = useState(props.default);
  const [buttonText, setButtonText] = useState(translate("global.advance"))
  const [isAddDisabled, setIsAddDisabled] = useState(props.isAddDisabled);
  const isPrpQuestion = componentName === "prp_question";
  const isVelogicaQuestion = componentName === "external_underwriting";
  const hasSubtitle = componentName === "broker_show_subtitle";
  const getPreviousIlnesses = previousIllnessesAnswered?.map((previousIllnes) => previousIllnes?.value)
  const leadRepository = new HttpLeadRepository(token);
  const handleThisQuestion = async () => {
    let answerResponse = type === "checkbox_optional" && !answer ? "none" : answer?.value ? answer.value : answer;
    await apiLeadQuestionPost(
      brokerId,
      leadId,
      questionId,
      answerResponse,
      token,
    ).then((res) => {
      leadRepository.getPostStackData(leadId).then((leadResponse) => {
        getTrackingUtms(leadId).then(utms => {

          const eventData = {
            intent: "loan",
            leadId: leadId,
            phone: leadResponse.lead.phone,
            email: leadResponse.lead.email,
            birthDate: leadResponse.lead.birthdate,
            calculatedCapital: parseInt(leadResponse.lead.capital),
            gender: leadResponse.lead.gender,
            calculatedPremium: parseFloat(leadResponse.price),
            postalCode: leadResponse.lead.zipcode,
            coverage: GUARANTEE[leadResponse.lead.insuranceType],
            entryPage: utms.entryPage,
            referrerUrl: utms.referrerUrl
          }

          handleTrackerQuestion({
            type: "FS",
            event: "Question Answered",
            data: {
              page: window.location.href,
              pageTitle: "Life5 Broker - QuestionAnswered",
              questionId: questionId,
              answerString: answerResponse,
              answerId: answerResponse,
              platform: 'Broker',
              stack: "Questions",
              ...eventData
            }
          });
          handleTrackerQuestion({
            type: "GA",
            data: {
              event: "virtualPageview",
              page: window.location.href,
              pageTitle: "Life5 Broker - QuestionAnswered",
              questionId: questionId,
              answers: answerResponse,
              platform: 'Broker',
              stack: "Questions",
              ...eventData
            },
          });
        })
      })
      handleNextQuestion(question.questionId, answerResponse);
      setAnswer(answerResponse);
      setIsAddDisabled(true);
    });
  };

  const handleAnswer = (e) => {
    const answerLength = e.length;
    const type = typeof e;
    if (answerLength !== undefined && type === "object") {
      let filterValue = [];
      filterValue = [];
      for (let i = 0; i < e.length; i++) {
        filterValue.push(e[i].value);
      }
      setAnswer(filterValue.toString());
      setDefaultValue(filterValue.toString());
      setIsAddDisabled(false);
    } else {
      setAnswer(e);
      setDefaultValue(e);
      setIsAddDisabled(false);
    }
  };

  const prepareText = (value) => {
    if (value !== null && value !== undefined) {
      if (value.includes("altura")) {
        return "Introduce la altura en centímetros.";
      }

      if (value.includes("peso")) {
        return "Introduce el peso en kg.";
      }
    }

    return "";
  };

  const handleKeyDown = (e) => {
    if (KEYS.KEY_ENTER === e.key && !!e.target.value.length) {
      handleThisQuestion();
    }
  };

  const handleKeyUp = (e) => {
    if (!e.target.value.length) {
      setIsAddDisabled(true);
    }
  };

  const handleSelectChange = (e) => {
    if (defaultValue !== e?.value) {
      setAnswer(e?.value ? e?.value : e);
    }
  };

  const handleSelectChangeDateInput = (e) => {
    if (defaultValue !== e) {
      setAnswer(e);
    }
  };

  useEffect(() => {
    if (isGetlifeBroker && answered === true) {
      setIsAnswered(true);
    }
  }, []);

  useEffect(() => {
    if (answer !== defaultValue && answer !== null) {
      handleThisQuestion();
    }
  }, [answer]);

  const handleOptionDisabled = (option) => {
    const arePreviousIlnessesSelected = getPreviousIlnesses?.includes(option?.value)
    return arePreviousIlnessesSelected
  }

  const [isAnswered, setIsAnswered] = useState(false);

  const handleIsAnswered = () => {
    setIsAnswered(false);
    handleIdEdited(questionId);
  };

  const parsedAnswer = isGetlifeBroker ? !isAnswered : !answered

  const filterComponent = (type) => {
    switch (type) {
      case "input":
        return (
          <TextInput
            onValueChange={handleAnswer}
            disabled={isGetlifeBroker ? isAnswered : answered}
            initialValue={answer || ""}
            onEnterKeyPress={handleKeyDown}
          />
        );

      case "text":
        return (
          <TextInput
            onValueChange={handleAnswer}
            disabled={isGetlifeBroker ? isAnswered : answered}
            initialValue={answer || ""}
            onEnterKeyPress={handleKeyDown}
          />
        );

        case "number":
          if (questionId === 7) {
            return (
              <TextInput
                onValueChange={handleAnswer}
                disabled={isGetlifeBroker ? isAnswered : answered}
                initialValue={answer || ""}
                onEnterKeyPress={handleKeyDown}
                type="numeric"
              />
            );
          } else if (questionId === 8) {
            return (
              <TextInput
                onValueChange={handleAnswer}
                disabled={isGetlifeBroker ? isAnswered : answered}
                initialValue={answer || ""}
                onEnterKeyPress={handleKeyDown}
                type="numeric"
              />
            );
          } else {
            return (
              <TextInput
                onValueChange={handleAnswer}
                disabled={isGetlifeBroker ? isAnswered : answered}
                initialValue={answer || ""}
                onKeyDown={handleKeyDown}
                onEnterKeyPress={handleKeyUp}
              />
            );
          }

      case "select":
        return (
          <SelectGetlife
            disabled={isGetlifeBroker ? isAnswered : answered}
            toMap={answers}
            handleValues={handleSelectChange}
            default={defaultValue}
            placeholder={props.placeholder}
            type={type}
          />
        );
      case "select_multiple":
        return (
          <SelectGetlife
            default={defaultValue}
            disabled={isGetlifeBroker ? isAnswered : answered}
            handleValues={handleAnswer}
            placeholder={props.placeholder}
            toMap={answers}
            type={type}
            defaultInputValue={previousIllnessesAnswered}
            isOptionDisabled={(option) => handleOptionDisabled(option)}
          />
        );

      case "checkbox":
        return (
          <SelectGetlife
            default={defaultValue}
            disabled={isGetlifeBroker ? isAnswered : answered}
            handleValues={handleAnswer}
            placeholder={parsedAnswer ? props.placeholder : "No, ninguno"}
            toMap={answers}
            type={type}
          />
        );

      case "checkbox_optional":
        return (
          <SelectGetlife
            default={defaultValue}
            disabled={isGetlifeBroker ? isAnswered : answered}
            handleValues={handleAnswer}
            placeholder={parsedAnswer ? props.placeholder : "No, ninguno"}
            toMap={answers}
            type={type}
          />
        );

      case "checkbox_mandatory":
        return (
          <SelectGetlife
            default={defaultValue}
            disabled={isGetlifeBroker ? isAnswered : answered}
            handleValues={handleAnswer}
            placeholder={parsedAnswer ? props.placeholder : "No, ninguno"}
            toMap={answers}
            type={type}
          />
        );

      case "select_button":
        return (
          <SelectGetlife
            disabled={isGetlifeBroker ? isAnswered : answered}
            toMap={answers}
            handleValues={handleSelectChange}
            default={defaultValue}
            placeholder={props.placeholder}
            type={type}
          />
        );
      case "calendar_extended":
        return (
          <CalendarExtendedGetlife
            default={defaultValue}
            disabled={isGetlifeBroker ? isAnswered : answered}
            handleValues={handleSelectChange}
            placeholder={props.placeholder}
            toMap={answers}
            type={type}
            answer={answer}
          />
        );

      case "radio":
      case "select_search":
        return (
          <SelectGetlife
            default={defaultValue}
            disabled={isGetlifeBroker ? isAnswered : answered}
            handleValues={handleSelectChange}
            placeholder={props.placeholder}
            toMap={answers}
            type={"select"}
          />
        );

      case "Text":
        return (
          <SelectGetlife
            default={props.default}
            disabled={isGetlifeBroker ? isAnswered : answered}
            handleValues={handleSelectChange}
            placeholder={props.placeholder}
            toMap={answers}
            type={type}
          />
        );

      case "boolean_question":
        return (
          <SelectGetlife
            disabled={isGetlifeBroker ? isAnswered : answered}
            toMap={answers}
            handleValues={handleSelectChange}
            default={defaultValue}
            placeholder={props.placeholder}
            type={type}
          />
        );

      default:
        break;
    }
  };
  const renderPrpQuestion = () => (
    <QuestionDateInput
      dateLabel={translate("productQuestions.label.dateLabel")}
      labelDateFrom={translate("productQuestions.label.labelDateFrom")}
      labelDatoTo={translate("productQuestions.label.labelDatoTo")}
      checkBoxLabel={translate("productQuestions.label.checkBoxLabel")}
      positionLabel={isAnswered ? defaultValue?.split("|")[0] : translate("productQuestions.label.positionLabel")}
      invalidDateMessage={translate("productQuestions.label.invalidDateMessage")}
      textYearFrom={isAnswered ? defaultValue?.split("|")[1]?.split("-")[0] : translate("productQuestions.label.textYear")}
      textMonthFrom={isAnswered ? defaultValue?.split("|")[1]?.split("-")[1] : translate("productQuestions.label.textMonth")}
      textYearTo={isAnswered ? defaultValue?.split("|")[2]?.split("-")[0] : translate("productQuestions.label.textYear")}
      textMonthTo={isAnswered ? defaultValue?.split("|")[2]?.split("-")[1] : translate("productQuestions.label.textMonth")}
      onValueChange={(e) => handleSelectChangeDateInput(e)}
      setValidateQuestionDateInput={() => ({})}
      isDisabled={isAnswered}
    />
  );

  const renderVelogica = () => (
    <VelogicaQuestion
      question={question}
    />
  );

  useEffect(() => {
    if (type === "checkbox_optional") {
      if (answer) {
        setButtonText(translate("global.advance"));
      }

      if (!answer) {
        setButtonText(translate("global.any"));
      }
    }

  }, [type, answer])

  return (
    <div
      className={
        isAnswered ? "question-mainContainerAnswered" : "question-mainContainer"
      }
    >
      <div className={!isVelogicaQuestion ? "question-container" : "question-containerVelogica"}>
      <div className="question--textContainer">
          <p
            className="question-question"
            style={{ color: isAnswered === true ? "#B2AFA4" : "" }}
            dangerouslySetInnerHTML={{ __html: content }}
          />
          <p
            className="question--textContainer__info"
            style={{ color: isAnswered === true ? "#B2AFA4" : "" }}
          >
            {prepareText(content)}
            {hasSubtitle && <div>
              <span dangerouslySetInnerHTML={{ __html: question?.subtitle }} />
            </div>
            }
          </p>
        </div>

        <div className="question-inputContainer">
          {label && (
            <p style={{ color: isAnswered ? "#c8c7bf" : "" }}>{label}</p>
          )}
          {isPrpQuestion ? renderPrpQuestion() : isVelogicaQuestion ? renderVelogica() : filterComponent(type)}
        </div>
        {isEditing === false && (isGetlifeBroker ? !isAnswered : !answered) && !isVelogicaQuestion && (
          <>
            {type !== "checkbox_optional" && <ButtonDS
              className="continue-button"
              onClick={() => handleThisQuestion()}
              disabled={type === "checkbox_optional" ? false : isAddDisabled}
              label={buttonText}
              buttonType={"primary"}
            />}
            {type === "checkbox_optional" && <ButtonDS
              className="continue-button"
              onClick={() => handleThisQuestion()}
              disabled={false}
              label={buttonText}
              buttonType={"primary"}
            />}
          </>
        )}
        {!isGetlifeBroker && answered && <div className="question-withoutEditButton" />}
        {isGetlifeBroker && <div className="question-pencilButtonContainer">
          {(isAnswered && !isNotEditable) && (
            <ButtonDS
              buttonType="secondary"
              className="continue-button"
              onClick={() => handleIsAnswered()}
              label={translate("productQuestions.button.edit")}
            />
          )}
        </div>}
      </div>
    </div>
  );
}