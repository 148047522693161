export const getTemplates = (type) => {
  let templates = ["offer-w-base"];

  if (type === "DEVIS") templates = ["devis"];

  if (type === "premium") {
    templates = ["offer-w-base", "ipa-any-cause-exclusions"];
  }

  if (type === "eg") {
    templates = ["serious-diseases-exclusions"];
  }

  if (type === "legal-note") templates = ["legal-note"];

  if (type === "ipid") templates = ["ipid"];

  return templates;
};