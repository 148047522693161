import { StatusFilter } from "./components/StatusFilter";
import {
  ListProjectsReference,
  ListProjectsV3,
  ListResponseV3,
} from "../../../api/request/Lists/Model/Response/ListResponse.model";
import {
  dateTimeToDate,
  validateEmailToTables,
} from "../../../utils/staticData";
import BrokerResponse from "../../../api/request/Broker/Model/Response/BrokerResponse.model";
import { formatPhone } from "./Utils/formatPhone";
import { renderstatus } from "./Utils/renderStatus";
import { columns, columnsAdmin, columnsIsGetlife } from "./constants/columns";
import { ButtonDS, GlobalIcon, InputDS, SelectDS } from "get-life-storybook-ts";
import { renderActions } from "./Utils/renderActions";
import { useNavigate } from "react-router";
import HttpLeadRepository from "../../../api/request/Lead/lead.service";
import { useContext } from "react";
import { ContextGetlife } from "../../../contexts/ContextGetlife";
import { markets } from "./constants/parseMarket";
import { ViewProjectData } from "../../../api/request/Lead/Model/EditProjectReponse.model";
import { getPrices } from "./Utils/getPrices";
import {
  pagination_buttons_left,
  pagination_buttons_right,
} from "./TableLife5.controller";
import jwtDecode from "jwt-decode";

interface projectsInfoI {
  translate: (key: string) => string;
  projects: ListProjectsV3[];
  totalItems: number;
  filterByStage: (stage: string) => ListResponseV3[];
  pageSize: number;
  handlePageSize: (value: string) => void;
  handlePagination: (value: string) => void;
  indexPage: number;
  totalPages: number;
}
interface table {
  projects: ListProjectsV3[];
  projectsInfo: projectsInfoI;
  broker: BrokerResponse | undefined;
  omniValue: string;
  setOmniValue: (e: string) => void;
  fetchProjects: ({
    stage,
    startD,
    endD,
    omniSearch,
    newPage,
    newPageSize,
  }: {
    stage?: string;
    startD?: string;
    endD?: string;
    omniSearch?: string;
    newPage?: number;
    newPageSize?: number;
  }) => Promise<void>;
  recoverProject: (leadId: string, project: any, updateLead: boolean) => void;
  setModalData: (data: any) => void;
  setShowModal: (value: boolean) => void;
  viewModal: boolean;
  setViewModal: (value: boolean) => void;
  otpModal: boolean;
  setOtpModal: (value: boolean) => void;
  otpUrl: string;
  setOtpUrl: (value: string) => void;
  rangeProps: any;
  selectRangeDate: number;
  setSelectRangeDate: (value: number) => void;
  viewData: ViewProjectData;
  setViewData: (data: ViewProjectData) => void;
  setCustomDateModal: (value: boolean) => void;
}

export const TableLife5 = ({
  projects,
  projectsInfo,
  broker,
  omniValue,
  setOmniValue,
  fetchProjects,
  otpModal,
  otpUrl,
  setOtpModal,
  setOtpUrl,
  recoverProject,
  setModalData,
  setShowModal,
  viewModal,
  setViewModal,
  rangeProps,
  selectRangeDate,
  setSelectRangeDate,
  viewData,
  setViewData,
  setCustomDateModal,
}: table) => {
  const { token, DOMAIN } = useContext(ContextGetlife);
  const leadRepository = new HttpLeadRepository(token);

  const { translate, totalItems, filterByStage } = projectsInfo;

  const navigate = useNavigate();

  const getColumns = () => {
    const user: any = jwtDecode(token);

    let col = columns;

    if (user?.roles && user?.roles.includes("ROLE_BROKER_SUPER_ADMIN")) {
      col = columnsAdmin;
    }
    if (broker?.brokerageId === 1) {
      col = columnsIsGetlife;
    }

    return col;
  };

  const renderTableHeader = () => {
    const columsToRender = getColumns();
    return columsToRender.map((column, key) => {
      return (
        <>
          <div className="flex-1" style={{ maxWidth: column.width }}>
            <div
              key={key}
              className="tableHeader flex BodyS font-bold uppercase"
            >
              {" "}
              {translate(column.name)}
            </div>
            {projects.map((project, key) => {
              return (
                <div
                  className={`tableBodyContainer w-full flex ${
                    key % 2 == 0 ? "rowOdd" : "rowEven"
                  }`}
                >
                  <div key={key} className={`tableBodyRow flex`}>
                    {
                      <div className="tableBody flex">
                        {parseData(project, column.reference)}
                      </div>
                    }
                  </div>
                </div>
              );
            })}
          </div>
        </>
      );
    });
  };

  const editLead = async (project: any) => {
    const data = await leadRepository.getViewProject(project.leadId);
    setModalData({
      ...data.personalData,
      ...data.product,
      leadId: project.leadId,
      status: project.status,
    });
    setShowModal(true);
  };

  const recoverLead = (leadId: string, project: any) => {
    recoverProject(leadId, project, false);
  };

  const viewLead = async (leadId: string, project: any) => {
    const data = await leadRepository.getPreviewProject(leadId);
    setViewData({ ...data, status: project.status, leadId: leadId });
    setViewModal(true);
  };

  const getOtp = async (leadId: string) => {
    const data = await leadRepository.getOtp(leadId);
    setOtpUrl(data.otpUrl);
    setOtpModal(true);
  };

  const buildTotals = () => {
    return `Pág. ${projectsInfo.indexPage} - Pág. ${projectsInfo.totalPages}`;
  };

  const parseData = (project: ListProjectsV3, ref: ListProjectsReference) => {
    if (ref === "phone") {
      return formatPhone(project.phone);
    }

    if (ref === "email") {
      return (
        <span className="containerText">
          {validateEmailToTables(translate, project.email)}
        </span>
      );
    }

    if (ref === "effectDate") {
      return dateTimeToDate(project.effectDate);
    }

    if (ref === "annualPrice") {
      if (project.annualPrice !== undefined) {
        return getPrices(translate, project.annualPrice as any);
      } else {
        return (
          <>
            <p>{translate("table.capital.notAvailable")}</p>
          </>
        );
      }
    }

    if (ref === "actions") {
      return renderActions(
        translate,
        project,
        recoverLead,
        editLead,
        getOtp,
        viewLead,
        broker,
        DOMAIN
      );
    }

    if (ref === "status") {
      return renderstatus(translate, project);
    }

    if (ref === "market") {
      return markets[project.market];
    }

    if (ref === "proprietor") {
      return <span className="containerText">{project.owner}</span>;
    }

    if (ref === "nameList") {
      return <span className="containerText">{project[ref]}</span>;
    }
  };

  const optionsPage = [
    {
      label: "10",
      value: "10",
    },
    {
      label: "15",
      value: "15",
    },
    {
      label: "20",
      value: "20",
    },
  ];

  const newProject = () => {
    navigate("/dashboard/calculator");
  };

  return (
    <>
      <div>
        <div className="w-full gap-4">
          <div className="pb-6">
            <p className="H3 font-bold text-dark-gray-100">
              {translate("projects.header.title")}
            </p>
            <p className="BodyM text-dark-gray-100">{`${totalItems} ${translate(
              "projects.header.subtitle"
            )}`}</p>
          </div>

          <div className=" flex flex-col gap-10">
            <StatusFilter filterByStage={filterByStage} />
            <div className="flex justify-between pb-6">
              <div className="w-[447px]">
                <InputDS
                  value={omniValue}
                  name="omniSearch"
                  label={translate("projects.search.label")}
                  icon="SearchIcon"
                  placeholder={translate("projects.search.placeholder")}
                  onChange={(e) => setOmniValue(e.target.value)}
                  clearInputCallback={() =>
                    fetchProjects({ omniSearch: "", newPage: 1 })
                  }
                  type="search"
                  className="w-[447px]"
                />
              </div>
              <div className="flex gap-4 items-end">
                <SelectDS
                  icon="FilterIcon"
                  label={translate("dateRange.label.filter")}
                  options={rangeProps.options}
                  value={selectRangeDate}
                  onChange={(e) => setSelectRangeDate(parseInt(e.target.value))}
                  className="!w-[420px]"
                />
                {selectRangeDate === -2 ? (
                  <ButtonDS
                    label="Seleccionar fechas"
                    buttonType="tertiary"
                    onClick={() => setCustomDateModal(true)}
                  />
                ) : null}
                <ButtonDS
                  onClick={() => newProject()}
                  label={translate("projects.noData.buttonText")}
                  buttonType={"primary"}
                  className="h-fit w-fit"
                  leftIcon="PlusIcon"
                />
              </div>
            </div>
          </div>

          <div className="tableProjectsContainer">
            <div className="tableHeaderContainer w-full flex">
              {renderTableHeader()}
            </div>
          </div>
          <div className="tableFooterContainer w-full">
            <div className="custom-table__footer">
              <div className="custom-table__rowsper"></div>
              <div className="custom-table__pagination">
                <div className="custom-table__pagination__totals BodyM font-medium text-[#555]">
                  {buildTotals()}
                </div>
                <div className="custom-table__pagination__buttons">
                  {pagination_buttons_left.map((element, index) => (
                    <button
                      key={index}
                      onClick={() => {
                        projectsInfo.handlePagination(element.value);
                      }}
                    >
                      <GlobalIcon
                        iconName={element.icon}
                        size="XS"
                        color="currentColor"
                      />
                    </button>
                  ))}
                </div>
                <div className="custom-table__pagination__buttons">
                  {pagination_buttons_right.map((element, index) => (
                    <button
                      key={index}
                      onClick={() => {
                        projectsInfo.handlePagination(element.value);
                      }}
                    >
                      <GlobalIcon
                        iconName={element.icon}
                        size="XS"
                        color="currentColor"
                      />
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="containerPage">
            <SelectDS
              label={translate("tables.footer.filter")}
              options={optionsPage}
              value={projectsInfo.pageSize}
              onChange={(e) => projectsInfo.handlePageSize(e.target.value)}
            />
          </div>
        </div>
      </div>
    </>
  );
};
