import { validate as uuidValidate } from "uuid";
import moment from "moment";
import { DEFAULT_WORKING_HOURS } from "get-life-storybook-ts/lib/components/WorkingHours/WorkingHours";

const GETLIFE_EMAIL = "@getlife.es";
const LIFE5_EMAIL = "@life5.com";
const DOMAIN = process.env.REACT_APP_DOMAIN_LOCALE;

export const months = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
];

export const GENDER = {
  Male: "M",
  Female: "F",
};

export const GUARANTEE = {
  basic: "F",
  premium: "FIPA",
  eg: "EG",
};

export const PAYMENT_METHOD = {
  email: "cardLinkpaymentEmail",
  sms: "cardLinkpaymentSms",
  now: "cardDirect",
};

export const initialValuesLandingCalcStep1 = {
  date: "",
  zipCode: "",
};

export const initialValuesLandingCalcStep2 = {
  capital: "",
  gender: "",
  email: "",
  phone: "",
  checked: false,
};

export const initialValuesFormCalcES = (translate) => ({
  ...initialValuesLandingCalcStep1,
  ...initialValuesLandingCalcStep2,
  relatedEmail: "",
  insuranceType: typeInsurance(translate).find((t) => t.value === "basic"),
  referenceName: "",
  birthday: "",
  market: "life5",
});

export const initialValuesFormCalcFR = {
  ...initialValuesLandingCalcStep1,
  ...initialValuesLandingCalcStep2,
  relatedEmail: "",
  referenceName: "",
  birthday: "",
  smoker: "",
  workingProfesion: "",
  workDistance: "",
  workHeights: "",
  workTools: "",
};

export const initialValuesFormCalcIT = {
  ...initialValuesLandingCalcStep1,
  ...initialValuesLandingCalcStep2,
  intention: "",
  relatedEmail: "",
  referenceName: "",
  birthday: "",
  smoker: "",
};

export const initialValuesFormCompany = {
  name: "",
  address: "",
  idCard: "",
  idDgsfp: "",
  contactEmail: "",
  contactPhone: "",
  logo: null,
  color: "",
  workingHours: structuredClone(DEFAULT_WORKING_HOURS),
};

export const requiredFieldsFormCalcBirth = [
  {
    name: "day",
    type: "string",
  },
  {
    name: "month",
    type: "string",
  },
  {
    name: "year",
    type: "date",
  },
];

export const requiredFieldsFormCalc = [
  {
    name: "capital",
    type: "string",
  },
  { name: "phone", type: "phone", label: "Teléfono", optional: false },
  { name: "email", type: "email", label: "Correo", optional: false },
];

export const requiredFieldsFormCalcFR = [
  { name: "phone", type: "phone", label: "Teléfono", optional: false },
  { name: "email", type: "email", label: "Correo", optional: false },
];

export const requiredFieldsFormCalcChecked = [
  ...requiredFieldsFormCalc,
  {
    name: "checked",
    type: "string",
  },
];

const modifiedOptional = (selectArray) => {
  const newArray = selectArray.map((item) => {
    if ("optional" in item) {
      return { ...item, optional: !item.optional };
    }
    return item;
  });
  return newArray;
};

export const requiredFormCalcDash = [
  ...modifiedOptional(requiredFieldsFormCalc),
  { name: "date", type: "date" },
  { name: "insuranceType", type: "string" },
];

export const requiredFormCalcDashIT = [
  ...modifiedOptional(requiredFieldsFormCalc),
  { name: "date", type: "string" },
  { name: "gender", type: "string" },
  { name: "capital", type: "string" },
  { name: "smoker", type: "string" },
  { name: "intention", type: "string" },
];

export const requiredFormCalcDashFR = [
  ...modifiedOptional(requiredFieldsFormCalcFR),
  { name: "date", type: "string" },
  { name: "gender", type: "string" },
  { name: "zipCode", type: "zipCode" },
  { name: "capital", type: "string" },
  { name: "smoker", type: "string" },
  { name: "workingProfesion", type: "string" },
  { name: "workDistance", type: "string" },
  { name: "workHeights", type: "string" },
  { name: "workTools", type: "string" },
];

export const requiredFieldsCompanyInfo = [
  {
    name: "name",
    type: "string",
  },
  {
    name: "address",
    type: "string",
  },
  {
    name: "idCard",
    type: "string",
  },
  {
    name: "idDgsfp",
    type: "string",
  },
  {
    name: "contactEmail",
    type: "email",
  },
  {
    name: "contactPhone",
    type: "phone",
  },
];

export const requiredFieldsBeneficiaries = {
  physicalPerson:
    DOMAIN === "es" || DOMAIN === "pt" || DOMAIN === "it"
      ? [
          {
            name: "name",
            type: "string",
          },
          {
            name: "lastName",
            type: "string",
          },
          {
            name: "lastName2",
            type: "string",
          },
          {
            name: "idNumber",
            type: "string",
          },
        ]
      : [
          {
            name: "name",
            type: "string",
          },
          {
            name: "lastName2",
            type: "string",
          },
          {
            name: "idNumber",
            type: "string",
          },
        ],
  legalPerson: [
    {
      name: "businessName",
      type: "string",
    },
    {
      name: "fiscalNumber",
      type: "string",
    },
  ],
  bankingEntity: [
    {
      name: "bankName",
      type: "string",
    },
    {
      name: "loanMortgageNumber",
      type: "string",
    },
  ],
};

export const requiredFieldsPostStack = {
  personalData: [
    {
      name: "name",
      type: "string",
    },
    {
      name: "lastName",
      type: "string",
    },
    {
      name: "email",
      type: "email",
    },
    {
      name: "phone",
      type: "phone",
    },
  ],
  nationality: [
    {
      name: "nationality",
      type: "string",
    },
    {
      name: "citizenship",
      type: "citizenship",
    },
    {
      name: "idNumber",
      type: "string",
    },
    {
      name: "nationality2",
      type: "string",
      optional: true,
    },
  ],
  address: [
    {
      name: "addressLine",
      type: "string",
    },
    {
      name: "zipcode",
      type: "zipCode",
    },
    {
      name: "province",
      type: "string",
    },
    {
      name: "city",
      type: "city",
    },
  ],
  beneficiaries: [],
};
export const requiredFieldsEmployee = [
  { name: "name", type: "string", label: "Nombre" },
  { name: "lastname", type: "string", label: "Apellidos" },
  { name: "phone", type: "phone", label: "Teléfono" },
];
export const requiredFieldsEmployeeEdit = [
  ...requiredFieldsEmployee,
  { name: "email", type: "email", label: "Correo" },
  { name: "rol", type: "string", label: "Rol" },
];

export const requiredFieldsForm = [
  ...requiredFieldsFormCalc,
  { name: "phone", type: "phone", label: "Teléfono" },
  { name: "email", type: "email", label: "Correo" },
];

export const genderOptions = (translate) => [
  {
    value: "Male",
    label: translate("calculator.gender.male"),
    id: "male",
    name: "male",
  },
  {
    value: "Female",
    label: translate("calculator.gender.female"),
    id: "female",
    name: "female",
  },
];

export const intentionCheckOut = {
  loan: "Hipoteca",
  family: "Familia",
  finance: "Préstamo",
};

export const intentionPurchase = (translate) => [
  { value: "loan", label: translate("calculator.intention.loan") },
  { value: "family", label: translate("calculator.intention.family") },
  { value: "finance", label: translate("calculator.intention.finance") },
];

export const typeInsurance = (translate) => [
  { value: "basic", label: translate("calculator.coverage.basic") },
  {
    value: "premium",
    label: translate("calculator.coverage.ipa"),
  },
];

export const validations = [
  {
    name: "zipCode",
    type: "string",
    errorText: "El código postal no es correcto",
    required: true,
    requiredText: "Este campo es obligatorio",
    regExp: /^(?:0[1-9]\d{3}|[1-4]\d{4}|5[0-2]\d{3})$/,
  },
  {
    name: "phone",
    type: "string",
    errorText: "Introduzca un teléfono válido",
    required: false,
    requiredText: "Este campo es obligatorio",
    regExp: /[ -]*(6|7|9)[ -]*([0-9][ -]*){8}/,
  },
  {
    name: "email",
    type: "string",
    errorText: "Introduzca un email válido",
    required: true,
    requiredText: "Este campo es obligatorio",
    regExp:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  },
];

export const textInsuranceModal = [
  { value: "basic", label: "Fallecimiento" },
  { value: "premium", label: "Fallecimiento + IPA" },
];

export const smokeOptions = (translate) => [
  { value: "yes", label: translate("calculator.boolean.yes") },
  { value: "no", label: translate("calculator.boolean.no") },
];

export const booleanOptions = (translate) => [
  { value: "true", label: translate("calculator.boolean.yes") },
  { value: "false", label: translate("calculator.boolean.no") },
];

export const workDistanceOptions = (translate) => [
  { value: "true", label: translate("calculator.workDistance.plus") },
  { value: "false", label: translate("calculator.workDistance.minus") },
];

export const getValidateEmail = (email) => {
  if (email !== undefined) {
    return uuidValidate(email.split(GETLIFE_EMAIL)[0]) ||
      uuidValidate(email.split(LIFE5_EMAIL)[0])
      ? ""
      : email;
  } else {
    return "";
  }
};

export const validateEmailToTables = (translate, email) => {
  if (email !== undefined) {
    return uuidValidate(email.split(GETLIFE_EMAIL)[0]) ||
      uuidValidate(email.split(LIFE5_EMAIL)[0])
      ? translate("table.email.empty")
      : email;
  } else {
    return translate("table.email.empty");
  }
};

export const dateTimeToDate = (datetime) => {
  return !datetime
    ? ""
    : moment(datetime, "YYYY-MM-DD hh:mm:ss").format("DD/MM/YYYY");
};

export const formatPrice = (price) => {
  return price ? price.toFixed(2) + " €" : "";
};

export const formatPriceTwoDecimals = (price) => {
  if (!price) {
    return "0,00 €";
  }
  return price.toFixed(2);
};

const prepareBodyModal = (type) => {
  if (type === "SavingGrafics") {
    return null;
  } else if (type === "NotSaving") {
    return (
      <div>
        <p>
          Eso significa o bien el ahorro no es muy relevante, o bien los datos
          introducidos no son coherentes. Por favor, revisa los datos y vuelve a
          intentarlo
        </p>
      </div>
    );
  } else if (type === "InsurancePortability") {
    return (
      <div>
        <p>
          En la siguiente pantalla te vamos a preguntar cuándo quieres empezar a
          estar cubierto por Life5. Te recomendamos que elijas la fecha de
          vencimiento de tu actual seguro, para que estés cubierto en todo
          momento sin pagar dos veces.
        </p>
      </div>
    );
  } else if (type === "LessOneMonth") {
    return (
      <div>
        <p>
          Ya que quedan menos de 30 días para la renovación de tu seguro de vida
          actual no podemos cancelarlo a tiempo sin que tengas que pagar la
          renovación.
        </p>
        <p>
          Si quieres continuar con la contratación para que te cancelemos tu
          seguro con el banco, ten en cuenta que hasta la fecha de vencimiento
          de tu actual seguro de vida (el año que viene) tendrás cobertura de
          Life5 y de tu seguro actual.
        </p>
        <p>
          Si prefieres, <strong>puedes volver más adelante</strong> cuando
          queden 3 meses hasta la renovación de tu seguro actual. Podremos
          gestionar tu cancelación y cubrirte con Life5, sin ningún coste
          añadido!
        </p>
      </div>
    );
  } else if (type === "MoreThreeMonths") {
    return (
      <div>
        <p>
          El precio que estás calculando puede variar de aquí a la fecha que
          indicas.
        </p>
        <p>
          Si quieres continuar con la contratación para que te cancelemos tu
          actual seguro, elige una fecha en los próximos 3 meses, pero{" "}
          <strong>
            ten en cuenta que durante un tiempo tendrás cobertura de Life5 y de
            tu seguro actual.
          </strong>
        </p>
        <p>
          Si prefieres, podemos ponernos en contacto contigo más adelante cuando
          queden 3 meses hasta la renovación de tu seguro actual. Podremos
          gestionar tu cancelación y cubrirte con Life5, sin ningún coste
          añadido!
        </p>
      </div>
    );
  }
};

export const typesModal = {
  SavingGrafics: {
    title: "Cámbiate a Life5 y ahorra en tu seguro de vida",
    firstButton: "Continúa con el cuestionario",
    SecondaryButton: "Descarga tu análisis completo",
    subText:
      "*El precio de Life5 no es definitivo, ya que necesitamos que respondas a nuestro cuestionario de estilo de vida para darte tu precio final. ",
  },
  NotSaving: {
    title: "¡Vaya! No hemos detectado un ahorro significativo con estos datos.",
    firstButton: "Continúa con el cuestionario",
    SecondaryButton: null,
  },
  InsurancePortability: {
    title:
      "¡Podremos gestionar tu cancelación y cubrirte con Life5, sin ningún coste añadido!",
    firstButton: "Continuar",
    SecondaryButton: null,
  },
  LessOneMonth: {
    title: "¡Vaya! No podemos cancelar tu seguro a tiempo.",
    firstButton: "Continuar",
    SecondaryButton: null,
  },
  MoreThreeMonths: {
    title:
      "Lo sentimos, Life5 no puede ofrecerte un seguro de vida a más de 3 meses en el futuro.",
    firstButton: "Continuar",
    SecondaryButton: null,
  },
};

export const selectModal = (type, mode, data) => {
  const result = typesModal[type];
  if (type === "SavingGrafics") {
    result.body = null;
  } else {
    result.body = prepareBodyModal(type);
  }

  return result;
};

export const paymentRadius = (translate) => [
  { value: "email", text: translate("payment.paymentMethod.email") },
  { value: "sms", text: translate("payment.paymentMethod.sms") },
  { value: "now", text: translate("payment.paymentMethod.now") },
];

export const billingType = (translate) => [
  { value: "monthly", label: translate("payment.paymentMethod.monthly") },
  { value: "yearly", label: translate("payment.paymentMethod.yearly") },
];
